.handleGrid-root {
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    grid-gap: 40px;
    justify-content: center;
}


.domainHandleGrid-root {
  display: grid;
  
  height: 65vh;
  overflow-y: scroll;
  overflow-x: hidden;
  
  grid-template-columns: repeat(auto-fill, 175px);
  grid-column-gap: 0px;
  grid-row-gap: 20px;
  justify-content: left;
  align-items: top;

  @media (max-width: 600px) {
    justify-content: center;
  }
}

.domain-model {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(100vh - 120px);
    z-index: 11;
    
    .domain-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: calc(100vh - 120px);;
        z-index: 12;
        background-color: #00000022;
    }
    
    .domain-model-content{
        position: absolute;
        left: calc((100vw * 0.45 )/2);
        top: 80px;
        display: flex;
        
        .domain-image {
            width: 100vw * 0.55;
            height: calc(100vw * 0.55 / 900 * 500);
        }
        
        .domain-model-text {
          position: absolute;
          left: calc((100vw * 0.45 )/ 6);
          top: calc(100vw * 0.45 / 900 * 500 / 1.85);
          width: 100vw * 0.42;
          height: 100px;
          display: flex;
          flex: auto;
          justify-content: center;
          
          .domain-model-text-box {
            height: 100px;

            .domain-model-name {
              font-family: 'Outfit';
              font-weight: 400;
              color: white;
              font-size: calc(36pt / 1280px * 100vw);
            }
      
            .domain-model-tld{
              font-family: 'Outfit';
              font-weight: 400;
              color: #69E82D;
              font-size: calc(36pt / 1280px * 100vw);
            }
          }
        }
    }

    .domain-model-button {
        position: absolute;
        left: calc((100% * 0.45 )/2 + 100% * 0.55 - 100px);
        top: calc(80px + 100vw * 0.55 / 900 * 500 + 20px);
        background-color: #4A4949;
        width: 100px;
        height: 40px;
        z-index: 13;
        border-radius: 22px;
        
        .domain-model-button-image {
          padding-left: 15px;
          padding-top: 7px;
          width: 40px;
        }
    }
    
}

.cropped {
  overflow: hidden;
  object-fit: cover;
  }