.domain-display-root {
    background-color: white;
    border-radius: 5px;
    font-family: 'Outfit';
    position: relative;
  
        .domain-title {
            position: absolute;
            top: 112px;
            left: 16px;
            width: 118px;
            text-align: center;
            font-size: 10pt;
            font-family: 'Outfit';

            .domain-name {
                font-weight: 600;
                color: white;
            }

            .domain-tld {
                font-weight: 400;
                color: #E0DEDE;
            }
        }

        .domain-bottom {
            position: absolute;
            top: 155px;
            left: 10px;
            width: 137px;
            height: 22px;
            text-align: left;

            .domain-bottom-name {
                font-family: 'Outfit';
                font-weight: 400;
                color: black;
                font-size: 8pt;
            }

            .domain-bottom-tld {
                font-family: 'Outfit';
                font-weight: 400;
                color: #00000088;
                font-size: 8pt;
            }
        }

        .owner-bottom {
            position: absolute;
            top: 170.38px;
            left: 10px;
            height: 22px;
            text-align: left;
            font-size: 8pt;
            font-family: 'Outfit';
            color: 4A4949;
            
            .owner-bottom-title {
                width: 31px;
                font-weight: 400;
            }

        }
      
        .owner-bottom-text {
            position: absolute;
            top: 170.38px;
            right: 10px;
            height: 22px;
            text-align: right;
            font-size: 8pt;
            font-family: 'Outfit';
            color: #4A494988;

            .owner-bottom-address {
                width: 62px;
                font-weight: 400;
            }
        }

        .float-items {
            position: absolute;
            z-index: 10;
            padding: 10px;
        }

        .float-icon {
            position: absolute;
            top: 10px;
            right: 12px;
            z-index: 10;
        }
      
        .domain-display {
            .display-bg {
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
            }
        }
  }