
.name {
  
  margin-left: 20%;
  width: 70vw;
  margin-top: 2%;
  overflow-x: hidden;

  &-header {
    
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 3.5%;

    @media (max-width: 600px) {
      justify-content: center;
    }

    &-input {
      :hover {
        border-color: transparent !important;
      }
      
      &-selector {
        background-color: #1E1E1E !important;
        width: 120px !important;
        height: 32px !important;
        border-color: transparent !important;

        // another format when width < 600px
        @media (max-width: 600px) {
          width: 40px !important;

          .ant-select-selection-item {
            visibility: hidden;
          }
        }
        
        .ant-select-item-option-selected {
          background-color: #404040 !important
        }
      
        .ant-select-item {
          color: var(--font-white) !important;
        }

        .ant-input-wrapper {
          border-color: transparent !important;
        }
        
      }

      .transfer-input{
        padding-left: 2%;
        // width as a proprtion of screen size
        width: 400px;
        height: 32px !important;

        // another format when width < 600px
        @media (max-width: 600px) {
          width: 260px !important;
          padding-left: 5px;
        }

        .ant-input-wrapper {
          border-color: transparent !important;
        }

        .ant-layout {
          height: 32px !important;
        }

        .ant-btn-primary {
          padding-left: 8px;
          padding-right: 8px;
          background-color: #165DFF !important;
          border-color: transparent !important;
          border-radius: 0px !important;
        }
      }  
    }
        
  }
  
  .scroll-button {
    position: absolute;
    bottom: 50px;
    right: 8%;
  }

  .position-wrapper {
    position: absolute;
    bottom: 0px;
    right: 0px;
    margin-top: 3%;
    margin-bottom: 3%;
    width: 100%;

    .paginator {
      display: flex;
      justify-content: center;

      .ant-pagination-item-link {
        background-color: transparent !important;
        border-color: transparent !important;
        color: var(--font-white) !important;
      }

      .ant-pagination-item {
        margin-left: 5px;
        margin-right: 5px;
        background-color: transparent !important;
        border-color: transparent !important;
         
        a {
          color: var(--font-white) !important;
        }
      }

      .ant-pagination-item-active {
        background-color: white !important;
        border-color: transparent !important;

        a {
          color: blue !important;
        }
      }
    }
      
  }

}

